import '@/commons';
import Vue from 'vue';
import { onMounted } from '@vue/composition-api';
import { Debug} from '@/shared/utils/helpers';
import Footer from '@/layout/footer';
import Menu from '@/layout/menu';
import Carga from '@/layout/carga';
import { toggles } from '@/observable';

const menu = Menu;
const carga = Carga;
const footer = Footer;

const DefaultPage = Vue.extend({
    setup() {
        const toggleMenu = () => {
            toggles.menu = !toggles.menu;
        };
        onMounted(() => {
            toggles.listo = !toggles.listo;
        });
        return {
            toggleMenu,
        };
    },
});
if (document.getElementById('default')) {
    const d = new DefaultPage({
        el: '#default',
        delimiters: ['[{', '}]'],
    });
}
